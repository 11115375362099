@import '../../../../styles/customMediaQueries.css';

.shoppingCartWrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.2s;
    border: 1px solid var(--colorBlack);
    border-bottom-color: transparent;
    padding: 0 12px;
    position: relative;

    svg {
        path {
            transition: stroke 0.3s ease;
        }
    }

    @media (--viewportLarge) {
        height: 40px;
        padding: 0 24px;
        margin: 15px 20px;
        border: 1px solid var(--colorBlack);
    }


    &:hover {
        background-color: var(--marketplaceColor);
        border: 1px solid var(--marketplaceColor);

        svg {
            path {
                stroke: var(--colorBlack);
                fill: var(--colorBlack)
            }
        }

        .itemsCount {
            transition: ease-in-out 0.2s;
            color: var(--colorBlack)
        }
    }
}

.itemsCount {
    font-size: 12px;
    color: var(--marketplaceColor);
    line-height: 2px;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: ease-in-out 0.2s;

    @media (--viewportLarge) {
        left: 50%;
    }
}

.cartItemsWrapper {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (--viewportLarge) {
        padding: 60px 52px 0 52px;
        gap: 30px;
    }
}

.cartItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.cartItemImage {
    max-width: 119px;
    /* height: 119px; */
    object-fit: cover;
    aspect-ratio: 1/1;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 100%;
}

.itemTitle {
    color: var(--colorBlack);
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.productSubCategory {
    font-weight: 450;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 17px;
}

.size {
    font-weight: 450;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: var(--colorBlack);
}

.cartItemBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.price {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: var(--colorBlack);
}

.remove {
    cursor: pointer;
    color: var(--colorFail);
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
}

.deleteIcon {
    cursor: pointer;
    margin-right: 1pc;
    margin-bottom: 3px;

    &:hover {
        transform: scale(1.02);
        color: red;
    }
}

.bottomWrapper {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 589px;
    background-color: var(--colorWhite);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (--viewportLarge) {
        padding: 16px 52px 60px 52px;
    }
}

.total {
    display: flex;
    justify-content: space-between;

    .price {
        font-size: 16px;
        line-height: 19px;
    }
}

.priceLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
}

.ctaButton {
    min-height: 45px;
    background-color: var(--colorBlack);
    border: 1px solid var(--colorBlack);
    color: var(--marketplaceColor);
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 1.35px;
    border-radius: 0;
    transition: ease-in-out 0.2s;

    &:hover,
    &:focus {
        background-color: var(--marketplaceColor);
        color: var(--colorBlack);
    }

    &:disabled:hover {
        background-color: var(--colorGrey100);
        color: var(--colorGrey700);
    }
}

.containerClassName {
    margin: 0 0 0 auto !important;
    min-height: 100vh !important;
    border-bottom: none !important;
    border-radius: 0 !important;
    flex-basis: 589px !important;
    padding: 0 !important;
}

.header {
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    color: rgba(28, 28, 28, 1);
}

.contentClassName {
    position: relative;
    width: 100%;
    height: 100%;

    @media (--viewportLarge) {
        min-height: calc(100vh - 116px - 156px);
    }
}

.emptyBag {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 30px;
    line-height: 53px;
    text-transform: uppercase;
    color: rgba(196, 196, 196, 1);
}

.longWord {
    word-break: break-word;
    hyphens: auto;
}