@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    background-color: var(--marketplaceColor);
    justify-content: center;

    @media (--viewportMedium) {
        gap: 12px;
    }

    @media (--viewportLarge) {
        justify-content: unset;
        gap: unset;
    }


}

.logoLink {
    display: none;

    @media (--viewportLarge) {
        display: block;
        z-index: -1;
        padding: 0 44px;
    }
}

.topbarLink {
    flex-shrink: 0;
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 16px;
    color: var(--colorBlack);
    text-transform: uppercase;

    height: 100%;
    padding: 0 12px 0 12px;
    margin: 0;

    &:hover {
        border-bottom: 4px solid var(--colorBlack);
        border-radius: 0;
        text-decoration: none;
    }

    @media (--viewportLarge) {
        line-height: 16px;
        margin: 0 24px 0 0;
    }
}

.topbarLinkLabel {
    /* composes: textSmall from global; */
    display: inline-block;
    margin: 22px 0;
    text-decoration: inherit;
    position: relative;

    @media (--viewportMedium) {
        letter-spacing: 2.86px;
    }
}